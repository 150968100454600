<style lang="scss" scoped>
@import "common/print-common";

.eTrip-section-cont {
    background: #fff;
}

@page {
    size: A4;
}

.print-info {
    height: auto;
}

.print-container {
    /*.title{*/
    /*    font-size: 8.47mm;*/
    /*    text-align: center;*/
    /*    font-family: '黑体';*/
    /*    span{*/
    /*        margin: 0 auto;*/
    /*    }*/
    /*    margin-bottom: 30px;*/
    /*}*/
    /*p{*/
    /*    font-size: 5.29mm;*/
    /*    font-family: '宋体';*/
    /*    color: #000;*/
    /*    display: flex;*/
    /*    margin-bottom: 10px;*/
    /*    min-height: 30px;*/
    /*    .value-text{*/
    /*        flex:1;*/
    /*        padding: 0 15px;*/
    /*        border-bottom: 1px solid #000;*/
    /*    }*/
    /*}*/
}

@media print {
    .btn {
        display: none;
    }
    .print-info-0 {
        height: 1260px;
    }
    .print-info-1 {
        height: 2560px;
    }
}
</style>
<style lang="scss">
.print-container /deep/ {
    .el-input__inner {
        background: transparent;
        outline: none;
        border-radius: 0;

        &:focus {
            border: 0;
        }
    }

    .el-input__suffix {
        display: none;
    }
}
</style>
<template>
    <div class="print-container">
        <el-main class="com-eTrip-section section-has-footer">
            <div class="eTrip-section-cont">
                <div id="print-content" v-loading="loading">
                    <div class="print-info" :class="'print-info-'+printType" v-for="printInfo in dataList"
                         :key="printInfo.id">
                        <div class="print-header">
                            <img src="./common/print_logo.png">
                            <h4>{{ printTitle }}管理台账</h4>
                        </div>
                        <div class="print-table" v-if="printType === 0">
                            <table>
                                <tr>
                                    <td colspan="6" class="table-title">{{ printTitle }}基本信息表</td>
                                </tr>
                                <tr>
                                    <td colspan="6" class="table-sub-title">驾驶员基本信息</td>
                                </tr>
                                <tr>
                                    <td>姓名</td>
                                    <td>
                                        <el-input v-model="printInfo.driverName" :maxLength="50"></el-input>
                                    </td>
                                    <td>性别</td>
                                    <td>
                                        <select-sex v-model="printInfo.driverGender"></select-sex>
                                    </td>
                                    <td>民族</td>
                                    <td>
                                        <el-input v-model="printInfo.driverNation" :maxLength="20"></el-input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>出生日期</td>
                                    <td>
                                        <el-date-picker style="width: 100%"
                                                        v-model="printInfo.driverBirthday"
                                                        type="date"
                                                        value-format="yyyy/MM/dd">
                                        </el-date-picker>
                                    </td>
                                    <td>手机号</td>
                                    <td>
                                        <el-input v-model="printInfo.driverMobile"
                                                  :maxLength="11"></el-input>
                                    </td>
                                    <td>注册日期</td>
                                    <td>
                                        <el-date-picker style="width: 100%"
                                                        v-model="printInfo.createTime"
                                                        type="date"
                                                        value-format="yyyy/MM/dd">
                                        </el-date-picker>
                                    </td>
                                </tr>
                                <tr>
                                    <td>身份证号</td>
                                    <td colspan="2">
                                        <el-input v-model="printInfo.idCardNumber" :maxLength="18"></el-input>

                                        <!--                                    @change="idCardChange"-->
                                    </td>
                                    <td>身份证有效期</td>
                                    <td colspan="2">
                                        <div class="table-term-validity">
                                            <el-date-picker type="date"
                                                            v-model="printInfo.idCardExpiryDateEff"
                                                            value-format="yyyy/MM/dd">
                                            </el-date-picker>
                                            <span>-</span>
                                            <el-date-picker type="date"
                                                            v-model="printInfo.idCardExpiryDateExp"
                                                            value-format="yyyy/MM/dd"></el-date-picker>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>身份证地址</td>
                                    <td colspan="5">
                                        <el-input v-model="printInfo.driverAddress" :maxLength="50"></el-input>
                                    </td>
                                </tr>
                                <!--                            <tr>-->
                                <!--                                <td>紧急联系人</td>-->
                                <!--                                <td>-->
                                <!--                                    <el-input v-model="printInfo.emergencyContact"></el-input>-->
                                <!--                                </td>-->
                                <!--                                <td>关系</td>-->
                                <!--                                <td></td>-->
                                <!--                                <td>联系方式</td>-->
                                <!--                                <td>-->
                                <!--                                    <el-input v-model="printInfo.emergencyContactPhone" :maxlength="11"></el-input>-->
                                <!--                                </td>-->
                                <!--                            </tr>-->
                                <tr>
                                    <td colspan="3">身份证正面存档</td>
                                    <td colspan="3">身份证反面存档</td>
                                </tr>
                                <tr>
                                    <td colspan="3" class="table-img">
                                        <div class="table-img-box">
                                            <div v-if="printInfo.idCardPhotoFace" class="preview_img">
                                                <el-image
                                                    :src="printInfo.idCardPhotoFace"
                                                    :preview-src-list="[printInfo.idCardPhotoFace]">
                                                </el-image>
                                            </div>
                                            <p v-else>暂无照片</p>
                                            <file-upload class="table-img-box-upload" id="idCardPhotoFace"
                                                         :showFileList="false"
                                                         :customStyle="true"
                                                         @onCompleted="handleImageUpload">
                                                <el-button>
                                                    上传
                                                </el-button>
                                            </file-upload>
                                        </div>
                                    </td>
                                    <td colspan="3" class="table-img">
                                        <div class="table-img-box">
                                            <div v-if="printInfo.idCardPhotoBack" class="preview_img">
                                                <el-image
                                                    :src="printInfo.idCardPhotoBack"
                                                    :preview-src-list="[printInfo.idCardPhotoBack]">
                                                </el-image>
                                            </div>
                                            <p v-else>暂无照片</p>
                                            <file-upload class="table-img-box-upload" id="idCardPhotoBack"
                                                         :showFileList="false"
                                                         :customStyle="true"
                                                         @onCompleted="handleImageUpload">
                                                <el-button>
                                                    上传
                                                </el-button>
                                            </file-upload>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="6" class="table-sub-title">驾驶证及从业资格证信息</td>
                                </tr>
                                <tr>
                                    <td>驾驶证号</td>
                                    <td colspan="2">
                                        <el-input v-model="printInfo.driverLicenseNumber" :maxLength="18"></el-input>
                                    </td>
                                    <td>准驾车型</td>
                                    <td colspan="2">
                                        <el-select v-model="printInfo.driverType">
                                            <el-option
                                                v-for="item in driverTypeList"
                                                :key="item"
                                                :label="item"
                                                :value="item"
                                            >
                                            </el-option>
                                        </el-select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>驾驶证地址</td>
                                    <td colspan="5">
                                        <el-input v-model="printInfo.driverLicenseAddress"></el-input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>初次领证日期</td>
                                    <td colspan="2">
                                        <el-date-picker style="width: 100%"
                                                        v-model="printInfo.getDriverLicenseDate"
                                                        type="date"
                                                        placeholder="选择日期"
                                                        value-format="yyyy/MM/dd">
                                        </el-date-picker>
                                    </td>
                                    <td>驾驶证有效期</td>
                                    <td colspan="2">
                                        <div class="table-term-validity">
                                            <el-date-picker type="date"
                                                            v-model="printInfo.driverLicenseOn"
                                                            value-format="yyyy/MM/dd">
                                            </el-date-picker>
                                            <span>-</span>
                                            <el-date-picker type="date"
                                                            v-model="printInfo.driverLicenseOff"
                                                            value-format="yyyy/MM/dd"></el-date-picker>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>从业资格证号</td>
                                    <td colspan="2">
                                        <el-input v-model="printInfo.certificateNo"
                                                  :maxLength="18"></el-input>
                                    </td>
                                    <td>初次领证日期</td>
                                    <td colspan="2">
                                        <el-date-picker type="date" v-model="printInfo.getNetworkCarProofDate"
                                                        style="width: 100%"
                                                        value-format="yyyy/MM/dd"></el-date-picker>
                                    </td>
                                </tr>
                                <tr>
                                    <td>从业资格证有效期</td>
                                    <td colspan="2">
                                        <div class="table-term-validity">
                                            <el-date-picker type="date"
                                                            v-model="printInfo.networkCarProofOn"
                                                            value-format="yyyy/MM/dd">
                                            </el-date-picker>
                                            <span>-</span>
                                            <el-date-picker type="date"
                                                            v-model="printInfo.networkCarProofOff"
                                                            value-format="yyyy/MM/dd"></el-date-picker>
                                        </div>
                                    </td>
                                    <td>发证机构</td>
                                    <td colspan="2">
                                        <el-input v-model="printInfo.networkCarIssueOrganization"></el-input>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="3">驾驶证存档</td>
                                    <td colspan="3">从业资格证存档</td>
                                </tr>
                                <tr>
                                    <td colspan="3" class="table-img">
                                        <div class="table-img-box">
                                            <div v-if="printInfo.driverLicensePhoto" class="preview_img">
                                                <el-image
                                                    :src="printInfo.driverLicensePhoto"
                                                    :preview-src-list="[printInfo.driverLicensePhoto]">
                                                </el-image>
                                            </div>
                                            <p v-else>暂无照片</p>
                                            <file-upload class="table-img-box-upload" id="driverLicensePhoto"
                                                         :showFileList="false"
                                                         :customStyle="true"
                                                         @onCompleted="handleImageUpload">
                                                <el-button>
                                                    上传
                                                </el-button>
                                            </file-upload>
                                        </div>
                                    </td>
                                    <td colspan="3" class="table-img">
                                        <div class="table-img-box">
                                            <div v-if="printInfo.certificatePhoto" class="preview_img">
                                                <el-image
                                                    :src="printInfo.certificatePhoto"
                                                    :preview-src-list="[printInfo.certificatePhoto]">
                                                </el-image>
                                            </div>
                                            <p v-else>暂无照片</p>
                                            <file-upload class="table-img-box-upload" id="certificatePhoto"
                                                         :showFileList="false"
                                                         :customStyle="true"
                                                         @onCompleted="handleImageUpload">
                                                <el-button>
                                                    上传
                                                </el-button>
                                            </file-upload>
                                        </div>
                                    </td>
                                </tr>

                            </table>
                        </div>
                        <div class="print-table" v-if="printType === 1">
                            <table>
                                <tr>
                                    <td colspan="6" class="table-title">{{ printTitle }}基本信息表</td>
                                </tr>
                                <tr>
                                    <td colspan="6" class="table-sub-title">车辆基本信息</td>
                                </tr>

                                <tr>
                                    <td>车辆所有人</td>
                                    <td colspan="5">
                                        <el-input v-model="printInfo.carOwner"></el-input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>车牌号</td>
                                    <td>
                                        <el-input v-model="printInfo.carNumberPlate" :maxLength="8"></el-input>
                                    </td>
                                    <td>车型品牌</td>
                                    <td colspan="3">
                                        <brand-model v-model="printInfo.carBrandModel"
                                                     @selectBrand="selectBrand"></brand-model>
                                    </td>
                                </tr>
                                <tr>
                                    <td>车型级别</td>
                                    <td colspan="2">
                                        <seat-model v-model="printInfo.carType"></seat-model>
                                    </td>
                                    <td>车身颜色</td>
                                    <td colspan="2">
                                        <el-select v-model="printInfo.carColor" style="width: 100%">
                                            <el-option
                                                v-for="car in vehicleColors"
                                                :key="car"
                                                :label="car"
                                                :value="car"
                                            >
                                            </el-option>
                                        </el-select>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="6" class="table-sub-title">行驶证相关信息</td>
                                </tr>
                                <tr>
                                    <td>品牌型号</td>
                                    <td colspan="5">
                                        <el-input v-model="printInfo.licenseCarModel"></el-input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>车辆识别代码</td>
                                    <td colspan="5">
                                        <el-input v-model="printInfo.vin"></el-input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>发动机号码</td>
                                    <td colspan="5">
                                        <el-input v-model="printInfo.engineId"></el-input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>车辆注册日期</td>
                                    <td>
                                        <el-date-picker style="width: 100%"
                                                        v-model="printInfo.carRegisterDate"
                                                        type="date"
                                                        value-format="yyyy/MM/dd">
                                        </el-date-picker>
                                    </td>
                                    <td>发证日期</td>
                                    <td>
                                        <el-date-picker style="width: 100%"
                                                        v-model="printInfo.issueDate"
                                                        type="date"
                                                        value-format="yyyy/MM/dd">
                                        </el-date-picker>
                                    </td>
                                    <td>核定载客人数</td>
                                    <td>
                                        <el-input v-model="printInfo.seats"></el-input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>下次年检日期</td>
                                    <td>
                                        <el-date-picker style="width: 100%"
                                                        v-model="printInfo.nextFixDate"
                                                        type="date"
                                                        value-format="yyyy/MM/dd">
                                        </el-date-picker>
                                    </td>
                                    <td>发动机排量</td>
                                    <td>
                                        <el-select v-model="printInfo.engineDisplace"
                                                   style="width: 100%">
                                            <el-option
                                                v-for="item in engineDisplaces"
                                                :key="item"
                                                :label="item"
                                                :value="item"
                                            >
                                            </el-option>
                                        </el-select>
                                    </td>
                                    <td>车辆轴距</td>
                                    <td>
                                        <el-input v-model="printInfo.wheelbase"></el-input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>燃油类型</td>
                                    <td>
                                        <el-select v-model="printInfo.fuelType" style="width: 100%">
                                            <el-option
                                                v-for="item in fuelTypes"
                                                :key="item"
                                                :label="item"
                                                :value="item"
                                            >
                                            </el-option>
                                        </el-select>
                                    </td>
                                    <td>是否新能源</td>
                                    <td>
                                        <el-select v-model="printInfo.plateColor" style="width: 100%">
                                            <el-option value="蓝色" label="否"></el-option>
                                            <el-option value="绿色" label="是"></el-option>
                                        </el-select>
                                    </td>
                                    <td>车辆使用性质</td>
                                    <td>
                                        <el-input v-model="printInfo.useCharacter"></el-input>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="3">行驶证正面存档</td>
                                    <td colspan="3">行驶证副页存档</td>
                                </tr>
                                <tr>
                                    <td colspan="3" class="table-img">
                                        <div class="table-img-box">
                                            <div v-if="printInfo.carLicenseFacePhoto" class="preview_img">
                                                <el-image
                                                    :src="printInfo.carLicenseFacePhoto"
                                                    :preview-src-list="[printInfo.carLicenseFacePhoto]">
                                                </el-image>
                                            </div>
                                            <p v-else>暂无照片</p>
                                            <file-upload class="table-img-box-upload" id="carLicenseFacePhoto"
                                                         :showFileList="false"
                                                         :customStyle="true"
                                                         @onCompleted="handleImageUpload">
                                                <el-button>
                                                    上传
                                                </el-button>
                                            </file-upload>
                                        </div>
                                    </td>
                                    <td colspan="3" class="table-img">
                                        <div class="table-img-box">
                                            <div v-if="printInfo.carLicenseBackPhoto" class="preview_img">
                                                <el-image
                                                    :src="printInfo.carLicenseBackPhoto"
                                                    :preview-src-list="[printInfo.carLicenseBackPhoto]">
                                                </el-image>
                                            </div>
                                            <p v-else>暂无照片</p>
                                            <file-upload class="table-img-box-upload" id="carLicenseBackPhoto"
                                                         :showFileList="false"
                                                         :customStyle="true"
                                                         @onCompleted="handleImageUpload">
                                                <el-button>
                                                    上传
                                                </el-button>
                                            </file-upload>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="3">车辆照片存档</td>
                                    <td colspan="3"></td>
                                </tr>
                                <tr>
                                    <td colspan="3" class="table-img">
                                        <div class="table-img-box">
                                            <div v-if="printInfo.carPhoto" class="preview_img">
                                                <el-image
                                                    :src="printInfo.carPhoto"
                                                    :preview-src-list="[printInfo.carPhoto]">
                                                </el-image>
                                            </div>
                                            <p v-else>暂无照片</p>
                                            <file-upload class="table-img-box-upload" id="carPhoto"
                                                         :showFileList="false"
                                                         :customStyle="true"
                                                         @onCompleted="handleImageUpload">
                                                <el-button>
                                                    上传
                                                </el-button>
                                            </file-upload>
                                        </div>
                                    </td>
                                    <td colspan="3" class="table-img">

                                    </td>
                                </tr>
                            </table>
                            <table class="page-next">
                                <tr class="open-up-w">
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td colspan="6" class="table-sub-title">车辆运输证信息</td>
                                </tr>
                                <tr>
                                    <td>运输证字号</td>
                                    <td colspan="2">
                                        <el-input v-model="printInfo.certificate"></el-input>
                                    </td>
                                    <td>发证机构</td>
                                    <td colspan="2">
                                        <el-input v-model="printInfo.transAgency"></el-input>
                                    </td>
                                </tr>
                                <tr>
                                    <td>运输证有效期</td>
                                    <td colspan="2">
                                        <div class="table-term-validity">
                                            <el-date-picker type="date"
                                                            v-model="printInfo.transDateStart"
                                                            value-format="yyyy/MM/dd">
                                            </el-date-picker>
                                            <span>-</span>
                                            <el-date-picker type="date"
                                                            v-model="printInfo.transDateStop"
                                                            value-format="yyyy/MM/dd"></el-date-picker>
                                        </div>
                                    </td>
                                    <td>是否过期</td>
                                    <td colspan="2">
                                        <el-select v-model="printInfo.isOverdue" style="width: 100%">
                                            <el-option :value="0" label="否"></el-option>
                                            <el-option :value="1" label="是"></el-option>
                                        </el-select>
                                    </td>
                                </tr>

                                <tr>
                                    <td colspan="3">运输证存档</td>
                                    <td colspan="3"></td>
                                </tr>
                                <tr>
                                    <td colspan="3" class="table-img">
                                        <div class="table-img-box">
                                            <div v-if="printInfo.transportCertificatePhoto" class="preview_img"
                                                 style="height: 520px">
                                                <el-image
                                                    :src="printInfo.transportCertificatePhoto"
                                                    :preview-src-list="[printInfo.transportCertificatePhoto]">
                                                </el-image>
                                            </div>
                                            <p v-else>暂无照片</p>
                                            <file-upload class="table-img-box-upload" id="transportCertificatePhoto"
                                                         :showFileList="false"
                                                         :customStyle="true"
                                                         @onCompleted="handleImageUpload">
                                                <el-button>
                                                    上传
                                                </el-button>
                                            </file-upload>
                                        </div>
                                    </td>
                                    <td colspan="3" class="table-img">

                                    </td>
                                </tr>

                                <tr>
                                    <td colspan="6" class="table-sub-title">车辆保险信息</td>
                                </tr>

                                <tr>
                                    <td>保险类型</td>
                                    <td>承保公司</td>
                                    <td>
                                        保单号
                                    </td>
                                    <td>保额（元）</td>
                                    <td colspan="2">有效期</td>
                                </tr>
                                <tr v-for="item in printInfo.carInsuranceDtos" :key="item.id">
                                    <td>{{ item.insurType }}</td>
                                    <td>
                                        <el-input
                                            type="textarea"
                                            autosize
                                            resize="none" v-model="item.insurCompany"></el-input>
                                    </td>
                                    <td>
                                        <el-input
                                            type="textarea"
                                            autosize
                                            resize="none"
                                            v-model="item.insurNum"></el-input>
                                    </td>
                                    <td>
                                        <el-input v-model="item.insurCount"></el-input>
                                    </td>
                                    <td colspan="2">
                                        <div class="table-term-validity">
                                            <el-date-picker type="date"
                                                            v-model="item.insurEff"
                                                            value-format="yyyy/MM/dd">
                                            </el-date-picker>
                                            <span>-</span>
                                            <el-date-picker type="date"
                                                            v-model="item.insurExp"
                                                            value-format="yyyy/MM/dd"></el-date-picker>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <ul class="print-footer">
                            <li>制表：</li>
                            <li>审核：</li>
                            <li>存档：</li>
                        </ul>
                    </div>
                </div>
            </div>
        </el-main>
        <el-footer class="com-eTrip-footer" height="64px">
            <div class="eTrip-footer-cont">
                <el-button type="primary" :disabled="loading" :loading="loading" style="float: right;margin-right: 20px"
                           v-print="printContent">
                    打印 x{{ dataList.length }}
                </el-button>
            </div>
        </el-footer>
    </div>

</template>
<script type="text/ecmascript-6">
import SelectSex from "@/components/selectSex/index";
import FileUpload from '@/components/fileUpload'
import BrandModel from "@/components/brandModel/index";
import SeatModel from "@/components/seatModel/index";
import {getSeatModel} from "@/data/index";
import {vehicleColors, fuelTypes, engineDisplaces} from '@/data/vehicleData/index'
import moment from "moment";

export default {
    data() {
        return {
            driverTypeList: ["A1", "A2", "A3", "B1", "B2", "C1", "C2", "C1M"],
            vehicleColors,
            engineDisplaces,
            fuelTypes,
            printInfo: {},
            complainId: 0,
            detail: {},
            url: '',// 这里是获取具体信息内容的接口地址
            dataList: [],
            loading: false,
            printContent: {
                id: '#print-content',
                url: '',
                preview: false, // 预览工具是否启用
                previewTitle: "900",
                popTitle: '900'
            }
        }
    },
    computed: {
        printTitle() {
            let type = this.printType,
                title = '';
            switch (type) {
                case 0:
                    title = '驾驶员';
                    break;
                case 1:
                    title = '车辆';
                    break;
            }
            return title
        },
        driverId() {
            return this.$route.params.id
        },
        printType() {
            return parseInt(this.$route.params.type)
        },
    },
    components: {
        SelectSex,
        // ImagePanel,
        FileUpload,
        BrandModel,
        SeatModel
    },
    mounted() {
        this.ids = this.$route.query.ids.split(',');
        this.loading = true;
        this.getData();
    },
    methods: {
        handleImageUpload({id, url}) {
            switch (id) {
                case 'idCardPhotoFace':
                    this.printInfo.idCardPhotoFace = url;
                    break;
                case 'idCardPhotoBack':
                    this.printInfo.idCardPhotoBack = url;
                    break;
                case 'driverLicensePhoto':
                    this.printInfo.driverLicensePhoto = url;
                    break;
                case 'certificatePhoto':
                    this.printInfo.certificatePhoto = url;
                    break;
                case 'carLicenseFacePhoto':
                    this.printInfo.carLicenseFacePhoto = url;
                    break;
                case 'carLicenseBackPhoto':
                    this.printInfo.carLicenseBackPhoto = url;
                    break;
                case 'carPhoto':
                    this.printInfo.carPhoto = url;
                    break;
                case 'transportCertificatePhoto':
                    this.printInfo.transportCertificatePhoto = url;
                    break;
            }
        }, //营业执照
        formatCarModel(type) {
            let info = {};
            if (type) {
                info = getSeatModel(parseInt(type));
            }
            return info;
        },
        selectBrand(val) {
            this.printInfo.carType = parseInt(val.carType);
            const carModel = this.formatCarModel(val.carType);
            this.printInfo.wheelbase = carModel.wheelbase || 0;
        },
        async getData(idx = 0) {
            try {
                const data = await Promise.all(new Array(10).fill('').map((item, i) => this.ids[idx + i]).filter((id) => id).map((id) => this.getDriverDetail(id)));
                data && this.dataList.push(...data);
            } finally {
                if (idx < this.ids.length - 1) {
                    this.getData(idx + 10);
                } else this.loading = false
            }
        },
        async getDriverDetail(id) {
            const url = this.printType === 0 ? '/galaxyDriverApproveApi/doDriverInfoDetails/' :
                '/galaxyDriverApproveApi/getCarInfoDetail/';
            let res = await this.http(`${url}${id}`);
            if (res.success) {
                const data = JSON.parse(JSON.stringify(res.data));
                if (data.personalCar !== 0) data.personalCar = 1;
                data.createTime = moment(data.createTime).format('YYYY/MM/DD')
                data.carType = parseInt(data.carType);
                data.carBrandModel = [data.carBrand, data.carModel];
                let today = new Date().getTime();
                if (data.transDateStop) data.isOverdue = (today === new Date(data.transDateStop).getTime()) ? 1 : 0;
                return data;
            }
        },
    }

}
</script>
