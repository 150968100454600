<!--
import selectSex from "@/components/selectSex/index";
<select-sex v-model="searchForm.saddle"></select-sex>
-->
<style lang="scss">
</style>
<template>

    <div>
        <el-select :value="value" @change="selectSex" :disabled="disabled" :placeholder="placeholder"
        style="width: 100%">
            <el-option
                    v-for="sex in sexList"
                    :key="sex.value"
                    :label="sex.label"
                    :value="sex.value"
            >
            </el-option>
        </el-select>

    </div>

</template>

<script type="text/ecmascript-6">
    export default {
        //定义模版数据
        data() {
            return {
                sexList:[
                    {value: "1", label: "男"},
                    {value: "2", label: "女"}
                ]
            }
        },
        props: {
            value: {
                type: String,
                default: "1"
            },
            disabled: {
                type: Boolean,
                default: false
            },
            placeholder: {
                type: String,
                default: "请选择"
            }
        },
        //计算属性
        computed: {},
        //主件被加载完成
        mounted: function () {

        },
        //定义事件方法
        methods: {
            selectSex(val){
                this.$emit("input", val);
            }
        },
        //监听模版变量
        watch: {}

    }
</script>