<!--
import BrandModel from "@/components/brandModel/index";
<brand-model v-model="searchForm.saddle"></brand-model>
-->
<style lang="scss">
</style>
<template>

    <div>
        <el-cascader
                placeholder="品牌"
                filterable
                :value="value" @change="selectBrand" :disabled="disabled"
                :options="brandModelList" style="width: 100%"
        ></el-cascader>

    </div>

</template>

<script type="text/ecmascript-6">
    import { uniqueArray } from "@/common/js/Utils";
    export default {
        //定义模版数据
        data() {
            return {
                brandModelList: []
            }
        },
        props: {
            value: {
                type: Array,
                default: function () {
                    return [];
                }
            },
            disabled: {
                type: Boolean,
                default: false
            },
            placeholder: {
                type: String,
                default: "请选择"
            }
        },
        //计算属性
        computed: {},
        //主件被加载完成
        mounted: function () {
            this.getBrandList();
        },
        //定义事件方法
        methods: {
            async getBrandList() {
                let res = await this.http(
                    "/galaxyStationApi/doCarBrandModelList",
                    {},
                    "POST"
                );
                if (res.success) {
                    let brandModelList = uniqueArray(res.data, "carBrand");
                    let carList = [];
                    brandModelList.forEach(item => {
                        let list = {
                            value: item.carBrand,
                            label: item.carBrand,
                            children: []
                        };
                        res.data.forEach(item1 => {
                            if (item.carBrand == item1.carBrand) {
                                let data = {
                                    value: item1.carModel,
                                    label: item1.carModel,
                                    carType: item1.carType
                                };
                                list.children.push(data);
                            }
                        });
                        carList.push(list);
                    });
                    this.brandModelList = carList;
                }
            },
            selectBrand(val){
                this.$emit("input", val);
                const brandModel = this.brandModelList.find(item=>{
                    return (item.value==val[0])
                })
                const info = brandModel.children.find((item)=>{
                    return (item.value==val[1])
                })
                this.$emit("selectBrand", info)
            }
        },
        //监听模版变量
        watch: {}

    }
</script>